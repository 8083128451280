<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-extended"
    >
      <CRow class="justify-content-center">
        <CCol sm="12" lg="12" class="mt-2">
          <div class="form-group form-row" rol="group">
            <label
              class="required col-form-label-sm col-sm-12 col-lg-3 text-right "
            >
              {{ $t("label.payrollType") }}
            </label>
            <div class="col-sm-12 col-lg-4 input-group-sm">
              <CSelect
              :options="payrollOptions"
              v-model.trim="$v.PayRollId.$model"
              :value.sync="PayRollId"
              :is-valid="hasError($v.PayRollId)"
              :invalid-feedback="errorMessage($v.PayRollId)"
              size="sm"
            />
            </div>

            <label
              class="required col-form-label-sm col-sm-12 col-lg-2 text-right "
            >
              {{ $t("label.quantity") }}
            </label>
            <div class="col-sm-12 col-lg-2 input-group-sm">
              <CInput 
                v-model.trim="$v.Quantity.$model"
                :value.sync="Quantity"
                :is-valid="hasError($v.Quantity)"
                :invalid-feedback="errorMessage($v.Quantity)"
                size="sm"
                @keypress="onlyNumbers($event)"
              />
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="12" class="mt-2">
          <div class="form-group form-row" rol="group">
            <label
              class="required col-form-label-sm col-sm-12 col-lg-3 text-right "
            >
              {{ $t("label.role") }}
            </label>
            <div class="col-sm-12 col-lg-8 input-group-sm">
              <CSelect
                :options="rolOptions"
                v-model.trim="$v.StowageRoleId.$model"
                :value.sync="StowageRoleId"
                :is-valid="hasError($v.StowageRoleId)"
                :invalid-feedback="errorMessage($v.StowageRoleId)"
                size="sm"
              >
              <template #append>
                <div class="d-flex align-items-start">
                  <CButton
                    style="padding-bottom: 0.08rem;"
                    color="add"
                    @click="showCollapse(0)" 
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.nuevo')+$t('label.role'),
                      placement: 'top-end'
                    }"
                    >
                    <CIcon name="cil-playlist-add"/>
                  </CButton>
                  </div>
                </template>
              </CSelect>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="12" class="mt-2">
          <div class="form-group form-row" rol="group">
            <label
              class="required col-form-label-sm col-sm-12 col-lg-3 text-right "
            >
              {{ $t("label.ubication") }}
            </label>
            <div class="col-sm-12 col-lg-8 input-group-sm">
              <CSelect
                :options="locationOptions"
                v-model.trim="$v.StowageUbicationId.$model"
                :value.sync="StowageUbicationId"
                :is-valid="hasError($v.StowageUbicationId)"
                :invalid-feedback="errorMessage($v.StowageUbicationId)"
                size="sm"
              >
              <template #append>
                <div class="d-flex align-items-start">
                  <CButton
                    style="padding-bottom: 0.08rem;"
                    color="add"
                    @click="showCollapse(2)" 
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.nuevo')+$t('label.location'),
                      placement: 'top-end'
                    }"
                    >
                    <CIcon name="cil-playlist-add"/>
                  </CButton>
                  </div>
                </template>
              </CSelect>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="12" class="mt-2">
          <div class="form-group form-row" rol="group">
            <label
              class="required col-form-label-sm col-sm-12 col-lg-3 text-right "
            >
              {{ $t("label.company") }}
            </label>
            <div class="col-sm-12 col-lg-8 input-group-sm">
              <CSelect
                :options="companyOptions"
                v-model.trim="$v.ClientTpId.$model"
                :value.sync="ClientTpId"
                :is-valid="hasError($v.ClientTpId)"
                :invalid-feedback="errorMessage($v.ClientTpId)"
                size="sm"
                :disabled="PayRollId == ''"
              >
              <template #append>
                <div class="d-flex align-items-start">
                  <CButton
                    style="padding-bottom: 0.08rem;"
                    color="add"
                    @click="showCollapse(1)" 
                    size="sm"
                    v-c-tooltip="{
                      content: $t('label.nuevo')+$t('label.company'),
                      placement: 'top-end'
                    }"
                    >
                    <CIcon name="cil-playlist-add"/>
                  </CButton>
                  </div>
                </template>
              </CSelect>
            </div>
          </div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="add" :disabled="isSubmit" @click="submit" size="sm">
          <CIcon name="checkAlt"/>
          <span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton color="wipe" @click="closeModal" :disabled="isSubmit" size="sm">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import { mapState } from 'vuex';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import RecursoValidations from '@/_validations/planificacion-estiba/recursoValidations';

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    isSubmit: false,

    titleModal: '',

    companyOptions: [],
    rolOptions: [],
    locationOptions: [],
    payrollOptions: [],
    newUnit: false,
    tercerizada: false,
    //MODELO
    ClientTpId: '',
    StowagePlanningId: '',
    StowageRoleId: '',
    StowagePlanningResourceId: '',
    PayRollId: '',
    StowageUbicationId: '',
    Quantity: 0,
  };
}
  //methods
//listas
async function getRolList () {
  this.loadingOverlay = true;
  this.rolOptions = [];

  let listado = Array;
  await this.$http.get("StowageRoleVessel-list", { StowageRoleId:'', TpVesselId: this.TpVessel, Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data];
    this.rolOptions = listado.map(listado => Object.assign({}, this.rolOptions, {
      value: listado.StowageRoleId,
      label: listado.StowageRoleName,
    }));

    this.rolOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function getLocationList() {
  this.loadingTable = true;
  let listado = [];
  await this.$http.get("StowageUbication-list", { Filter: 'ACTIVO' })
  .then(response => {
    listado = response.data.data;

    this.locationOptions = listado.map(listado => Object.assign({}, this.locationOptions, {
      value: listado.StowageUbicationId,
      label: listado.StowageUbicationName,
    }));

    this.locationOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.loadingTable = false;
  });
}

async function getCompanyList () {
  this.loadingOverlay = true;
  this.companyOptions = [];

  let listado = Array;
  await this.$http.get("ClientActivityList-by-PayRollId", { PayRollId: this.PayRollId })
  .then(response => {
    listado = response.data.data;
    this.companyOptions = listado.map(listado => Object.assign({}, this.companyOptions, {
      value: listado.ClientTpId,
      label: listado.ClientName
    }));

    this.companyOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function getPayRollList () {
  this.loadingOverlay = true;
  this.payrollOptions = [];

  let listado = Array;
  await this.$http.get("PayRoll-list")
  .then(response => {
    listado = [...response.data.data].filter(ob => ob.Status==="ACTIVO");
    this.payrollOptions = listado.map(listado => Object.assign({}, this.payrollOptions, {
      value: listado.PayRollId,
      label: listado.PayRollName,
      FgSupplier: listado.FgSupplier
    }));

    this.payrollOptions.unshift({
      value: "",
      label: this.$t('label.select'),
      FgSupplier:false
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}
//otras funciones
function refrescarSelect(valor) {
  switch(valor) {
    case 0:
      this.getRolList();
      break;
    case 1:
      if(this.computedShowCompanyBoolean){
        this.getCompanyList();
      }
      break;
    case 2:
      this.getLocationList();
      break;
    case 3:
      this.getRolList();
      if(this.computedShowCompanyBoolean){
        this.getCompanyList();
      }
      this.getLocationList();
      break;
    }
}

function showCollapse(valor) {
  this.newUnit = valor;
  this.showModal = valor;
}

function onlyNumbers(e) {
  var regex = new RegExp('^[0-9.,]+$');
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
      return true;
  }
  e.preventDefault();
  return false;
}
//otros
function closeModal() {
  this.refreshComponent();
  this.$emit('child-refresh', true);
  this.showModal=false;
}

function refreshComponent() {
  this.isSubmit = false;
  this.tercerizada = false;
  this.titleModal = '';
  this.StowagePlanningResourceId = '';
  this.StowageRoleId = '';
  this.ClientTpId = '';
  this.PayRollId = '';
  this.StowageUbicationId = '';
  this.Quantity = 0;
  this.$v.$reset();
}

async function submit(){
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$invalid) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let StowageUbicationJson = [];
    let StowagePlanningRoleJson = [];

    StowageUbicationJson.push({
      StowageUbicationId: this.StowageUbicationId,
      Quantity: this.Quantity,
    });

    StowagePlanningRoleJson = {
      StowagePlanningId: this.StowagePlanningId,
      StowageRoleId: this.StowageRoleId,
      StowagePlanningResourceId: this.StowagePlanningResourceId ? this.StowagePlanningResourceId : null,
      PayRollId: this.PayRollId,
      ClientTpId: this.ClientTpId,
      StowageUbicationJson: StowageUbicationJson
    };

    let method = 'POST';
    let ruta = 'DeviceStowagePlanningUser-insert';

    await this.$http
      .ejecutar (method, ruta, StowagePlanningRoleJson, { root: 'StowagePlanningRoleJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          this.closeModal();
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Exito!',
            text: response.data.data[0].Response,
            type: "success"
          });
        }
      })
      .catch((e) => {
      this.loadingOverlay = false;
      this.isSubmit = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: "error"
        });
      }).then(() => {
      this.loadingOverlay = false;
      this.isSubmit = false;
    });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
} 

//computeds
function computedShowCompanyBoolean(){
  if(this.PayRollId !== '' && this.PayRollId !== null && this.payrollOptions.length !== 0){
    const Index = this.payrollOptions.map(function(e) {
      return e.value; 
    }).indexOf(this.PayRollId);
    if(Index !== -1){
      return this.payrollOptions[Index].FgSupplier;
    }
    return false;
  }
  return false;
}

export default {
  name: 'modal-resource-assign',
  data,
  props: {
    modal: null,
  },
  mixins: [mixinGeneral],
  directives: UpperCase,
  validations() {
    return RecursoValidations();
  },
  watch: {
    modal: async function () {
      if (this.modal) {
        this.showModal = true; 
        this.getRolList();
        this.getLocationList();
        this.getPayRollList();

        if (this.modal==true) {
          this.refreshComponent();
          this.titleModal = this.$t('label.nueva')+' '+this.$t('label.asignResource');

        } else {
          this.titleModal  = this.$t('label.edit')+' '+this.$t('label.asignResource')+': '+this.modal.ClientName;
          this.tercerizada = false;
          this.StowagePlanningResourceId = this.modal.StowagePlanningResourceId;
          this.StowageRoleId = this.modal.StowageRoleId;
          this.StowagePlanningId = this.modal.StowagePlanningId;
          this.PayRollId = this.modal.PayRollId;
          this.Quantity = this.modal.Quantity;
          this.StowageUbicationId = this.modal.StowageUbicationId;

          this.ClientTpId = this.modal.ClientTpId;
          if(this.computedShowCompanyBoolean){
            this.getCompanyList();
          }

          this.Status = this.modal.FgActStowagePlanningResource ? 1 : 0;
          this.FgActStowagePlanningResource = this.modal.FgActStowagePlanningResource ? 1 : 0;
          
          this.$v.$touch();
        }
        this.$emit('closeModal');
      }
    },
    PayRollId: function (newValue){
      if(newValue != '' && this.computedShowCompanyBoolean){
        this.getCompanyList();
      }
    },
  },
  methods: {
    closeModal,
    refreshComponent,
    submit,
    getRolList,
    refrescarSelect, //modal recurso
    showCollapse,
    getLocationList,
    getCompanyList,
    getPayRollList,
    onlyNumbers,
  },
  computed: {
    computedShowCompanyBoolean,
    ...mapState({
      collapsePlanificacion: (state) => state.planificacionestiba.collapsePlanificacion,
      tabIndex: (state) => state.planificacionestiba.tabIndex,
      user: (state) => state.auth.user,   
      TpVessel: state => state.planificacionestiba.TpVesselId,
      StowagePlanning: state => state.planificacionestiba.planificacionId,

    }),
  },
};
</script>
<style lang="scss">
</style>
